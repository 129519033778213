import {Component, OnInit, Input, ViewChild, Output, TemplateRef} from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'parkplatz-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() dataSource: MatTableDataSource<object>;
  @Input() columnDefinition: {def: string, header: string, data?: string | TemplateRef<any>}[];
  @Input() id = 'id';
  @Input() editable = true;
  @Input() disabled = false;
  @Input() paginationText: string;
  @Output() clickEdit: EventEmitter<number> = new EventEmitter();
  @Output() clickDelete: EventEmitter<number> = new EventEmitter();
  private displayedColumns: string[];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    // Internationalization Pagination
    this.paginator._intl.itemsPerPageLabel = this.paginationText + ' pro Seite';
    this.paginator._intl.firstPageLabel = 'Erste Seite';
    this.paginator._intl.lastPageLabel = 'Letzte Seite';
    this.paginator._intl.previousPageLabel = 'Vorherige Seite';
    this.paginator._intl.nextPageLabel = 'Nächste Seite';
    this.paginator._intl.getRangeLabel = function(page, pageSize, length) {
      if (length === 0 || pageSize === 0) { return `0 von ${length}`; }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} – ${endIndex} von ${length}`; };
    this.dataSource.paginator = this.paginator;
    this.displayedColumns = this.columnDefinition.map(x => x.def);
    if (this.editable) {
      this.displayedColumns.push('edit');
    }
  }

  edit(id: number) {
    this.clickEdit.emit(id);
  }

  delete(id: number) {
    this.clickDelete.emit(id);
  }

  private isTemplateRef(value: any): boolean {
    return value instanceof TemplateRef;
  }
}
