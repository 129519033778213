import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule} from '@angular/material';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoggerModule } from 'ngx-logger';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { PagenotfoundComponent } from './page-not-found/page-not-found.component';
import { UserComponent } from './user/user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpInterceptorProviders } from './auth/authInterceptor';
import { MenuComponent } from './menu/menu.component';
import { UserModule } from './user/user.module';
import { SharedUiModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { RangesFooter } from './reservations/ranges-footer.component';

const loggerConfig = {
  serverLoggingUrl: `${environment.apiURL}/log/logs`,
  level: environment.logLevel,
  serverLogLevel: environment.serverLogLevel,
  disableConsoleLogging: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PagenotfoundComponent,
    UserComponent,
    MenuComponent,
    RangesFooter,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatIconModule,
    MatTableModule,
    MatSelectModule,
    MatTooltipModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    UserModule,
    SharedUiModule,
    LoggerModule.forRoot(loggerConfig),
  ],
  entryComponents: [
    UserComponent,
    RangesFooter,

  ]
  ,
  providers: [
    HttpInterceptorProviders,
    {provide: LOCALE_ID, useValue: 'de'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

