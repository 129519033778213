import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NumberPlateComponent } from './number-plate/number-plate.component';
import { MatInputModule, MatTableModule, MatSortModule,
   MatPaginatorModule, MatIconModule, MatButtonModule,
   MatCardModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatSlideToggleModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NumberPlateEditComponent } from './number-plate-edit/number-plate-edit.component';
import { TableComponent } from './table/table.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { FormComponent } from './form/form.component';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';



@NgModule({
  declarations: [
    NumberPlateComponent,
    NumberPlateEditComponent,
    TableComponent,
    ConfirmDialogComponent,
    ProfileEditComponent,
    FormComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    SatDatepickerModule,
    SatNativeDateModule,

  ],
  exports: [
    NumberPlateComponent,
    NumberPlateEditComponent,
    TableComponent,
    ProfileEditComponent,
    FormComponent
  ],
  providers: [
    ConfirmDialogService,
  ],
  entryComponents: [
    ConfirmDialogComponent,
  ]
})
export class SharedUiModule { }
