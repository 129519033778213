import {Component, Input, OnInit} from '@angular/core';
import {NumberPlate} from '../../model/numberplate.model';

@Component({
  selector: 'app-number-plate',
  templateUrl: './number-plate.component.html',
  styleUrls: ['./number-plate.component.scss']
})
export class NumberPlateComponent implements OnInit {

  @Input() numberplate: NumberPlate;

  constructor() { }

  ngOnInit() {
  }

}
