import { Component, DoCheck, OnInit } from '@angular/core';
import { NumberPlate } from '../model/numberplate.model';

import { Tenant } from '../model/tenant.model';
import { ReservationService } from '../reservations/reservation.service';
import { TokenStorageService } from '../auth/tokenStorage.service';
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})

export class UserComponent implements OnInit, DoCheck {
  saved = false;
  user: Tenant = null;
  loaded = false;
  numberplate: NumberPlate[] = [];
  handovernumberplate: NumberPlate = {} as NumberPlate;
  removedPlates: NumberPlate[] = [];
  idControl = new FormControl();
  firstnameControl = new FormControl();
  lastnameControl = new FormControl();
  emailControl = new FormControl();
  controlNumberplate: FormControl[] = [];
  tenant = false;
  userProfileControl = [];
  control = [];
  Form: FormGroup;
  changed = false;


  constructor(
    public resService: ReservationService,
    public tokenService: TokenStorageService,
    private dialogService: ConfirmDialogService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public router: Router,


  ) {
    this.resService.SetGuest(null);
    this.resService.SetSelectedMenu('user');

   }

  ngOnInit() {
    this.resService.SetSelectedMenu('user');
    if (this.authService.hasTenantPermission()) {
      this.tenant = true;
    }
    this.resService.getTenantById(this.tokenService.getTokenId()).subscribe(renter => {
      this.user = renter;
      this.idControl = new FormControl(this.user.id, [Validators.required]);
      this.idControl.disable();
      this.firstnameControl = new FormControl(this.user.firstname, [Validators.required]);
      this.firstnameControl.disable();
      this.lastnameControl = new FormControl(this.lastnameControl, [Validators.required]);
      this.lastnameControl.disable();
      this.emailControl = new FormControl(this.user.email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9-]+\\.[a-z]{2,4}$')]);
      this.userProfileControl = [this.idControl,
        this.firstnameControl, this.lastnameControl, this.emailControl];
      this.resService.getNumberplatesOfTenant(this.user.id).subscribe( numberplate => {
        this.numberplate = numberplate;
        let i = 0;
        while (this.numberplate.length > i) {
          this.controlNumberplate.push(new FormControl(this.numberplate[i].number, [Validators.required, Validators.maxLength(15)]));
          i += 1;
        }
        this.control = this.userProfileControl;
        i = 0;
        while (this.controlNumberplate.length > i) {
          this.control.push(this.controlNumberplate[i]);
          i += 1;
        }
        this.Form = this.formBuilder.group(this.control);
        this.loaded = true;
      });
    });
  }

  ngDoCheck(){
    if(this.Form.dirty){
      this.changed = true;
    }
  }

  addPlate(controlplate: FormControl[]) {
    this.control = [this.idControl,
      this.firstnameControl, this.lastnameControl, this.emailControl];
    this.controlNumberplate = controlplate;
    let i = 0;
    while (this.controlNumberplate.length > i) {
      this.control.push(this.controlNumberplate[i]);
      i += 1;
    }
    this.Form = this.formBuilder.group(this.control);
  }

  removePlate(numberplate: NumberPlate) {
    this.control = [this.idControl,
      this.firstnameControl, this.lastnameControl, this.emailControl];
    this.controlNumberplate = [];
    const index = this.numberplate.indexOf(numberplate, 0);
    if (index > -1) {
      this.numberplate.splice(index, 1);
    }
    let i = 0;
    while (this.numberplate.length > i) {
      this.controlNumberplate.push(new FormControl(numberplate[i].number, [Validators.required, Validators.maxLength(15)]));
      i += 1;
    }
    i = 0;
    while (this.controlNumberplate.length > i) {
      this.control.push(this.controlNumberplate[i]);
      i += 1;
    }
    this.Form = this.formBuilder.group(this.control);
    this.changed = true;
  }

  btnSaveClicked() {
    let j = 0;
    while (this.removedPlates.length > j) {
      if (this.removedPlates[j].id) {
        this.resService.deleteNumberplate(this.removedPlates[j].id).subscribe();
      }
      j += 1;
    }
    this.resService.updateTenant(this.tokenService.getTokenId(), this.user).subscribe();
    this.resService.updateNumberplate(this.tokenService.getTokenId(), this.numberplate).subscribe(
      () => {
        this.resService.getNumberplatesOfTenant(this.tokenService.getTokenId()).subscribe(
          num => {
            this.saved = true;
            this.numberplate = num;
            this.dialogService.open({
              message: 'Änderungen erfolgreich gespeichert',
              confirmText: 'Ok',
              icon: 'done',
            });
            this.dialogService.confirmed().subscribe(
              confirmed => {
                window.location.reload();
              }
            )
          }
        );
      },
      () => {
        this.dialogService.open({
          message: 'Speichern fehlgeschlagen',
          confirmText: 'Ok',
          icon: 'error',
        });
        this.resService.getNumberplatesOfTenant(this.tokenService.getTokenId()).subscribe(
          num => {
            this.numberplate = num;
          }
        );
      }
    );
  }
}
