import {Component, Input, OnInit} from '@angular/core';
import {NumberPlate} from '../../model/numberplate.model';

@Component({
  selector: 'parkplatz-number-plate-edit',
  templateUrl: './number-plate-edit.component.html',
  styleUrls: ['./number-plate-edit.component.scss']
})
export class NumberPlateEditComponent implements OnInit {

  @Input()
  numberplate: NumberPlate;

  @Input()
  numberplateControl: [];

  constructor() {
   }

  ngOnInit() {
  }

}
