import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'parkplatz-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  @Input()
  isDisabled = true;
  @Input()
  model: any;
  @Input()
  customClass: string;
  @Input()
  label: string;
  @Input()
  Control: FormControl;
  @Output()
  modelChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

}
