import { Injectable, ResolvedReflectiveFactory } from '@angular/core';

import { throwError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { TenantViewNameAndRole } from '../model/tenant.model';
import { Tenant } from '../model/tenant.model';
import { Parkingspot } from '../model/parkingspot.model';
import { RegistrationData } from './registration/registrationdata.model';
import { CarPark } from '../model/carpark.model';
import { Reservation } from '../model/reservation.model';
import { ViewReservation } from '../model/viewreservation.model';
import { ParkingspotViewCarpark } from '../model/parkingspotviewcarpark.model';
import { CarParkTableDTO } from '../model/carparkTableDTO.model';
import { OrganisationTableDTO } from '../model/organisationTableDTO.model';
import { Organisation } from '../model/organisation.model';
import { ParkingspotTableDTO } from '../model/parkingspotTableDTO.model';
import { TenantTableDTO } from '../model/tenantTableDTO.model';
import { ReservationTableDTO } from '../model/reservationTableDTO.model';
import { NumberPlate } from '../model/numberplate.model';
import { OrganisationName } from '../model/organisationname.model';
import { CarparkOrganisation } from '../model/carparkOrganisation.model';

@Injectable({
  providedIn: 'root',
})
export class ManagementService {
  private apiURL = environment.apiURL + '/admin';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    })
  };

  private selectedTab: string = null;
  private tenant: string;
  private organisation: OrganisationName;
  private saveOrganisation = 2;

  constructor(
    private httpClient: HttpClient,
  ) { }

  getManageTenantList(id: number): Observable<TenantViewNameAndRole[]> {
    return this.httpClient.get<TenantViewNameAndRole[]>(this.apiURL + '/managetenantlist/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageParkingSpotList(): Observable<Parkingspot[]> {
    return this.httpClient.get<Parkingspot[]>(this.apiURL + '/manageparkingspotlist', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageParkingSpotById(id: number | string): Observable<Parkingspot> {
    return this.httpClient.get<Parkingspot>(this.apiURL + '/manageparkingspot/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateParkingspot(id: number |string, parkingspot: Parkingspot): Observable<Parkingspot> {
    return this.httpClient.put<Parkingspot>(this.apiURL + '/parkingspot/' + id, parkingspot, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteParkingspot(id: number | string) {
    return this.httpClient.delete(this.apiURL + '/parkingspot/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageCarparkList(): Observable<CarPark[]> {
    return this.httpClient.get<CarPark[]>(this.apiURL + '/carparklist', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageCarparkListByOrganisation(id: number | string): Observable<CarPark[]> {
    return this.httpClient.get<CarPark[]>(this.apiURL + '/carparklist/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getOrganisationById(id: number | string): Observable<Organisation> {
    return this.httpClient.get<Organisation>(this.apiURL + '/organisation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageCarparkTable(): Observable<CarParkTableDTO[]> {
    return this.httpClient.get<CarParkTableDTO[]>(this.apiURL + '/carparktable', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageOrganisationTable(): Observable<OrganisationTableDTO[]> {
    return this.httpClient.get<OrganisationTableDTO[]>(this.apiURL + '/organisationtable', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageParkingspotTableByOrganisation(id: number | string): Observable<ParkingspotTableDTO[]> {
    return this.httpClient.get<ParkingspotTableDTO[]>(this.apiURL + '/parkingspottable/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageParkingspotTableByOwner(id: number | string): Observable<ParkingspotTableDTO[]> {
    return this.httpClient.get<ParkingspotTableDTO[]>(this.apiURL + '/parkingspottableowner/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageParkingspotTable(): Observable<ParkingspotTableDTO[]> {
    return this.httpClient.get<ParkingspotTableDTO[]>(this.apiURL + '/parkingspottable', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageTenantTableByOrganisation(id: number | string): Observable<TenantTableDTO[]> {
    return this.httpClient.get<TenantTableDTO[]>(this.apiURL + '/tenanttable/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getBackofficeTenantTableByOrganisation(id: number | string): Observable<TenantTableDTO[]> {
    return this.httpClient.get<TenantTableDTO[]>(this.apiURL + '/backofficetenanttable/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageTenantTable(): Observable<TenantTableDTO[]> {
    return this.httpClient.get<TenantTableDTO[]>(this.apiURL + '/tenanttable', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getManageReservationTableByOrganisation(id: number | string): Observable<ReservationTableDTO[]> {
    return this.httpClient.get<ReservationTableDTO[]>(this.apiURL + '/reservationtable/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageReservationTable(): Observable<ReservationTableDTO[]> {
    return this.httpClient.get<ReservationTableDTO[]>(this.apiURL + '/reservationtable', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageCarparkById(id: number | string): Observable<CarPark> {
    return this.httpClient.get<CarPark>(this.apiURL + '/carpark/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getManageOrganisationById(id: number | string): Observable<Organisation> {
    return this.httpClient.get<Organisation>(this.apiURL + '/organisation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateCarpark(id: number |string, carpark: CarPark): Observable<CarPark> {
    return this.httpClient.put<CarPark>(this.apiURL + '/carpark/' + id, carpark, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateOrganisation(id: number |string, organisation: Organisation): Observable<Organisation> {
    return this.httpClient.put<Organisation>(this.apiURL + '/organisation/' + id, organisation, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteCarpark(id: number | string) {
    return this.httpClient.delete(this.apiURL + '/carpark/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getReservationById(id: number | string): Observable<ViewReservation> {
    return this.httpClient.get<ViewReservation>(this.apiURL + '/reservation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postReservation(data: Reservation): Observable<any> {
    return this.httpClient.post<Reservation>(this.apiURL + '/reservation', JSON.stringify(data), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postReservationRange(reservation: Reservation[]): Observable<Reservation[]> {
    return this.httpClient.post<Reservation[]>(this.apiURL + '/reservations', JSON.stringify(reservation), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateReservation(id: number |string, reservation: Reservation): Observable<Reservation> {
    return this.httpClient.put<Reservation>(this.apiURL + '/reservation/' + id, reservation, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteReservation(id: number | string) {
    return this.httpClient.delete(this.apiURL + '/reservation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteTenant(id: number | string) {
    return this.httpClient.delete(this.apiURL + '/managetenant/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  checkLoginExistence(username: string): Promise<boolean> {
    return this.httpClient.get<boolean>(this.apiURL + '/exists/' + username, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  postRegistration(data: RegistrationData): Observable<any> {
    return this.httpClient.post<RegistrationData>(this.apiURL + '/register', JSON.stringify(data), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postCarpark(data: CarPark): Observable<any> {
    return this.httpClient.post<CarPark>(this.apiURL + '/carpark', JSON.stringify(data), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postOrganisation(data: Organisation): Observable<any> {
    return this.httpClient.post<Organisation>(this.apiURL + '/organisation', JSON.stringify(data), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postParkingspot(data: Parkingspot): Observable<any> {
    return this.httpClient.post<Parkingspot>(this.apiURL + '/parkingspot', JSON.stringify(data), this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTenantById(id: number | string): Observable<Tenant> {
    return this.httpClient.get<Tenant>(this.apiURL + '/tenant/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateTenant(id: number |string, tenant: Tenant): Observable<Tenant> {
    return this.httpClient.put<Tenant>(this.apiURL + '/tenant/' + id, tenant, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateNumberplate(id: number, numberplate): Observable<NumberPlate[]> {
    return this.httpClient.put<NumberPlate[]>(this.apiURL + '/numberplate/' + id,  numberplate, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getNumberplatesOfTenant(id: number) {
    return this.httpClient.get<NumberPlate[]>(this.apiURL + '/numberplatelist/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteNumberplate(id: number) {
    return this.httpClient.delete(this.apiURL + '/numberplate/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getParkingSpotViewCarparkByOrganisation(id: number) {
    return this.httpClient.get<ParkingspotViewCarpark[]>(this.apiURL + '/parkingspotViewCarpark/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getParkingSpotViewCarpark() {
    return this.httpClient.get<ParkingspotViewCarpark[]>(this.apiURL + '/parkingspotViewCarpark', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getOrganisationname() {
    return this.httpClient.get<OrganisationName[]>(this.apiURL + '/organisationname', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getCarparkOrganisation() {
    return this.httpClient.get<CarparkOrganisation[]>(this.apiURL + '/carparkorganisation', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getCarparkOrganisationByOrganisation(id: number) {
    return this.httpClient.get<CarparkOrganisation[]>(this.apiURL + '/carparkorganisation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getOrganisationnameByTenantId(id: number | string ) {
    return this.httpClient.get<OrganisationName>(this.apiURL + '/organisationname/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteOrganisation(id: number | string) {
    return this.httpClient.delete(this.apiURL + '/organisation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
    } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
/*     window.alert(errorMessage);
 */ return throwError(errorMessage);
  }

  getSelectedTab(): string {
    return this.selectedTab;
  }

  setSelectedTab(tab: string) {
   this.selectedTab = tab;
  }

  SetTenant(tenant: string) {
    this.tenant = tenant;
  }

  GetTenant(): string {
    return this.tenant;
  }

  SetOrganisation(organisation: OrganisationName) {
    this.organisation = organisation;
  }

  GetOrganisation(): OrganisationName {
    return this.organisation;
  }

  SetSaveOrganisation(orga: number) {
    this.saveOrganisation = orga;
  }

  GetSaveOrganisation(): number {
    return this.saveOrganisation;
  }
}

