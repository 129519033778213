import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  message = '';
  username = '';
  password = '';
  loaded = false;

  constructor(
    public authService: AuthService,
    public router: Router
    ) {
  }

  ngOnInit() {
    if (this.authService.isUserLoggedIn()) {
      this.redirect();
    } else {
      this.loaded = true;
    }
  }

  /**
   * Initializes the login routine.
   * Updates the displayed message to appropriate notes.
   * Redirects upon success.
   */
  login() {
    this.loaded = false;
    this.authService.login(this.username, this.password)
    .then(() => {
      this.loaded = true;
      this.redirect();
    })
    .catch(() => {
      this.loaded = true;
      this.message = 'Anmeldung fehlgeschlagen!\nBitte überprüfen Sie ihren\n Benutzernamen und ihr Passwort.';
    });
  }

  redirect() {
      // Get the redirect URL from our auth service
      // If no redirect has been set, use the default
      const redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/reservation';

      // Set our navigation extras object that passes on our global query params and fragment
      const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'preserve',
        preserveFragment: true
      };
      // Redirect the user
      this.router.navigateByUrl(redirect, navigationExtras);
  }
}

