import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {TokenStorageService} from './auth/tokenStorage.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private token: TokenStorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Custom Error: ${error.error.message}`;
          } else {
            // server-side error
            if (error.status === 403) {
              this.token.signOut();
              this.router.navigate(['/login']);
              return EMPTY;
            }
            errorMessage = `Custom Error Code: ${error.status}\nMessage: ${error.message}\nType: ${typeof error.status}`;
          }
          // window.alert(errorMessage);
          return throwError(errorMessage);
        })
      );
  }
}
