import { Component, OnInit, Input, Output, AfterViewChecked} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'parkplatz-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers:  [   {provide: MAT_DATE_LOCALE, useValue: 'de'},
  {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}]
})
export class FormComponent implements OnInit, AfterViewChecked {
  @Input() title: string;
  @Input() backButton: boolean;
  @Input() formfield: {datePicker?: boolean, date?: Date, data?: string, header: string, minlength: number, control: FormControl[]}[];
  @Input() toggleFront: boolean;
  @Input() guest: boolean;
  @Input() toggleDouble: boolean;
  @Input() daterange: boolean;
  @Input() onStartDisabled: boolean;
  @Output() saveClick: EventEmitter<any> = new EventEmitter();
  @Output() selectFront: EventEmitter<any> = new EventEmitter();
  @Output() selectDouble: EventEmitter<any> = new EventEmitter();
  @Output() selectOrga: EventEmitter<any> = new EventEmitter();
  @Output() selection: EventEmitter<any> = new EventEmitter();
  @Output() changeGuest: EventEmitter<any> = new EventEmitter();
  @Output() changePeriod: EventEmitter<any> = new EventEmitter();
  @Output() selectcountry: EventEmitter<any> = new EventEmitter();
  @Output() selectInitial: EventEmitter<any> = new EventEmitter();
  @Output() selectNumber: EventEmitter<any> = new EventEmitter();
  @Output() addorganisation: EventEmitter<any> = new EventEmitter();
  @Output() removeorganisation: EventEmitter<any> = new EventEmitter();





  Form: FormGroup;
  control = [];

  constructor(
    private location: Location,
    private dialogService: ConfirmDialogService,
    private formBuilder: FormBuilder) {
   }


  ngOnInit() {
    let i = 0;
    while (this.formfield.length > i ) {
      if (this.formfield[i].control.length <= 1) {
      this.control.push(this.formfield[i].control);
      } else {
        let j = 0;
        while (this.formfield[i].control.length > j ) {
          this.control.push(this.formfield[i].control[j]);
          j += 1;
        }
      }
      i += 1;
    }
    this.Form = this.formBuilder.group(this.control);
    if (this.onStartDisabled) {
      this.ngAfterViewChecked();
    }
  }

  ngAfterViewChecked() {
    let i = 0;
    this.control = [];
    while (this.formfield.length > i ) {
      if (!this.formfield[i].control.length) {
      this.control.push(this.formfield[i].control);
      } else {
        let j = 0;
        while (this.formfield[i].control.length > j ) {
          this.control.push(this.formfield[i].control[j]);
          j += 1;
        }
      }
      i += 1;
    }
    this.Form = this.formBuilder.group(this.control);
  }

  btnSaveClicked() {
    this.saveClick.emit();
  }

  selectedFront(event) {
    this.selectFront.emit(event);
  }

  selectedDouble(event) {
    this.selectDouble.emit(event);
  }

  selected(event) {
    this.selection.emit(event);
  }

  selectedcountry(event) {
    this.selectcountry.emit(event);
  }

  selectedInitial(event) {
    this.selectInitial.emit(event);
  }

  selectedNumber(event) {
    this.selectNumber.emit(event);
  }

  btnBackClicked() {
    this.location.back();
  }

  setTime(event, time) {
    time.setHours(event.split(':')[0], event.split(':')[1]);
  }

  selectdate(event) {
    let i = 0;
    while (this.formfield.length > i) {
      if (this.formfield[i].datePicker === true) {
        this.formfield[i].date = new Date(event);
      }
      i += 1;
    }
  }

  guestChanged() {
    this.changeGuest.emit();
    this.ngAfterViewChecked();
  }

  daterangeChanged() {
    this.changePeriod.emit();
  }

  addOrganisation() {
    this.addorganisation.emit();
  }

  removeOrganisation(event) {
    this.removeorganisation.emit(event);
  }

  selectedOrga(event) {
    this.selectOrga.emit(event);
  }
}
