import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TokenStorageService } from '../auth/tokenStorage.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PagenotfoundComponent implements OnInit {
  user: string;

  constructor(
    private location: Location,
    private tokenService: TokenStorageService,
    private logger: NGXLogger,
    ) {
      this.logger.info(this.logger.getConfigSnapshot());
  }

  ngOnInit() {
    this.user = this.tokenService.getTokenName();
  }

  btnBackClicked() {
    this.location.back();
  }
}
