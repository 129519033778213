import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { AuthToken } from './authToken';

const AUTHTOKEN_KEY = 'AuthToken';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private tokenData = {} as AuthToken;
  private isCached = false;
  private isStored = false;

  constructor() {
    const storedToken = window.sessionStorage.getItem(AUTHTOKEN_KEY);
    if (storedToken !== null) {
      this.isStored = true;
      this.cacheAuthTokenDecoded(storedToken);
    }
  }

  public signOut() {
    window.sessionStorage.clear();
    this.isStored = false;
    this.tokenData = {} as AuthToken;
    this.isCached = false;
  }

  public saveAuthToken(token: string) {
    window.sessionStorage.removeItem(AUTHTOKEN_KEY);
    window.sessionStorage.setItem(AUTHTOKEN_KEY, token);
    this.isStored = true;
    this.cacheAuthTokenDecoded(token);
  }

  private cacheAuthTokenDecoded(token: string) {
    this.tokenData = this.decodeAuthToken(token);
    this.tokenData.authToken = token;
    this.isCached = true;
  }

  private decodeAuthToken(token: string) {
    if (token === undefined || token === null) { return; }
    return jwt_decode(token);
  }

  public getAuthToken(): string {
    if (this.isCached) { return this.tokenData.authToken; }
    if (this.isStored) {
      this.cacheAuthTokenDecoded(sessionStorage.getItem(AUTHTOKEN_KEY));
      return this.getAuthToken();
    }
    return null;
  }

  public getTokenId(): number {
    if (this.isCached) { return this.tokenData.id; }
    if (this.isStored) {
      this.cacheAuthTokenDecoded(this.getAuthToken());
      return this.getTokenId();
    }
    return null;
  }

  public getTokenName(): string {
    if (this.isCached) { return this.tokenData.name; }
    if (this.isStored) {
      this.cacheAuthTokenDecoded(this.getAuthToken());
      return this.getTokenName();
    }
    return null;
  }

  public getTokenTenant(): boolean {
    if (this.isCached) { return this.tokenData.tenant; }
    if (this.isStored) {
      this.cacheAuthTokenDecoded(this.getAuthToken());
      return this.getTokenTenant();
    }
    return null;
  }

  public getTokenGuest(): boolean {
    if (this.isCached) { return this.tokenData.guest; }
    if (this.isStored) {
      this.cacheAuthTokenDecoded(this.getAuthToken());
      return this.getTokenGuest();
    }
    return null;
  }

  public getTokenAdmin(): boolean {
    if (this.isCached) { return this.tokenData.administrator; }
    if (this.isStored) {
      this.cacheAuthTokenDecoded(this.getAuthToken());
      return this.getTokenAdmin();
    }
    return null;
  }

  public getTokenBackoffice(): boolean {
    if (this.isCached) { return this.tokenData.backoffice; }
    if (this.isStored) {
      this.cacheAuthTokenDecoded(this.getAuthToken());
      return this.getTokenBackoffice();
    }
    return null;
  }

  public getTokenOwner(): boolean {
    if (this.isCached) { return this.tokenData.owner; }
    if (this.isStored) {
      this.cacheAuthTokenDecoded(this.getAuthToken());
      return this.getTokenOwner();
    }
    return null;
  }
}
