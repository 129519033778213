import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogOptions } from './confirm-dialog-options';

@Injectable()
export class ConfirmDialogService {
  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private dialog: MatDialog,
    ) { }

  public open(options: ConfirmDialogOptions) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
         data: {
           title: options.title,
           message: options.message,
           message2: options.message2,
           cancelText: options.cancelText,
           confirmText: options.confirmText,
           icon: options.icon,
         }
    });
    if (options.timeout !== undefined && options.timeout !== null && options.timeout > 0) {
      setTimeout(() => {
        this.dialogRef.close('timeout');
      }, options.timeout);
    }
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }

}


