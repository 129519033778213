import {Component, Input, OnInit, Output} from '@angular/core';
import {NumberPlate} from '../../model/numberplate.model';
import {TokenStorageService} from '../../auth/tokenStorage.service';
import { FormControl, Validators } from '@angular/forms';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'parkplatz-number-plate-management',
  templateUrl: './number-plate-management.component.html',
  styleUrls: ['./number-plate-management.component.scss']
})
export class NumberPlateManagementComponent implements OnInit {

   @Input()
   plates: NumberPlate[];
   @Input()
   removedPlates: NumberPlate[];
   @Input()
   numberplateControl: FormControl[];

   @Output()
   addplate: EventEmitter<any> = new EventEmitter();

   @Output()
   removeplate: EventEmitter<any> = new EventEmitter();



  constructor(
    public tokenService: TokenStorageService,
  ) { }

  ngOnInit() {
  }

  addNumberplate() {
    const added = {} as NumberPlate;
    added.tenantid = this.tokenService.getTokenId();
    this.numberplateControl.push(new FormControl(added.number, [Validators.required]));
    this.plates.push(added);
    this.addplate.emit(this.numberplateControl);
  }

  removeNumberplate(numberplate) {
    this.removedPlates.push(numberplate);
    const index = this.plates.indexOf(numberplate, 0);
    if (index > -1) {
      this.plates.splice(index, 1);
    }
    this.removeplate.emit(this.plates);
  }

}
