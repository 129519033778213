import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { Tenant } from '../model/tenant.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthResponse } from './authResponse';
import { TokenStorageService } from './tokenStorage.service';
import { OrganisationName } from '../model/organisationname.model';

const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  });

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // store the URL so we can redirect after logging in
  redirectUrl: string;
  user: Tenant;
  role: string;
  private organisation: OrganisationName;


  private authURL = environment.apiURL + '/auth';

  constructor(
    private httpClient: HttpClient,
    private token: TokenStorageService,
  ) {}

  async login(username: string, password: string): Promise<boolean> {
    const authResp: AuthResponse = await this.authenticate(username, password).then(
      response => {
        return response;
      }
    );
    if (authResp != null) {
      this.token.saveAuthToken(authResp.authToken);
      console.log(this.token);
      return true;
    }
    return false;
  }

  logout(): Observable<boolean> {
    return of(true).pipe(
      tap(() => {
        this.token.signOut();
      })
    );
  }

  authenticate(username: string, password: string): Promise<AuthResponse> {
    const httpParams = new HttpParams()
      .set('username', username)
      .set('password', password);
    return this.httpClient.post<AuthResponse>(this.authURL + '/validateLogin', httpParams,
      { headers: httpHeaders, params: httpParams, withCredentials: true}).toPromise();
  }

  /**
   * Checks whether the logged in user has the requested permission stored / granted.
   * Either hirarchical or explicit or combination
   * @param permission the method/function/key to check
   */
  hasAdminPermission(): boolean {
    return this.token.getTokenAdmin();
  }

  hasBackofficePermission(): boolean {
    return this.token.getTokenBackoffice();
  }

  hasGuestPermission(): boolean {
    return this.token.getTokenGuest();
  }

  hasTenantPermission(): boolean {
    return this.token.getTokenTenant();
  }

  hasOwnerPermission(): boolean {
    return this.token.getTokenOwner();
  }

  getTokenID(): number {
    return this.token.getTokenId();
  }

  hasPermission(permission: string) {
        // this.token.getTokenId();
    // TODO: implement here
  }

  isUserLoggedIn() {
    const username = this.token.getTokenName();
    const userid = this.token.getTokenId();
    //const userrole = this.token.getTokenRole();
    return !(username === null) && !(userid === null);
  }

  SetOrganisation(organisation: OrganisationName) {
    this.organisation = organisation;
  }

  GetOrganisation(): OrganisationName {
    return this.organisation;
  }

}
