import { Component, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { TokenStorageService } from '../auth/tokenStorage.service';
import { AuthService } from '../auth/auth.service';
import { ManagementService } from '../management/management.service';
import { FormControl } from '@angular/forms';
import { OrganisationName } from '../model/organisationname.model';
import { distinct } from 'rxjs/operators';
import { ReservationService } from '../reservations/reservation.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit, OnDestroy, DoCheck {
  username: string;
  sub: Subscription;
  enabled = true;
  organisations: string[] = [];
  orgaName: OrganisationName[];
  selected: string;
  tenantpermission = false;
  guestpermission = false;
  adminpermission = false;
  backofficepermission = false;
  disabled = true;
  selectedMenu = '';
  formControl = new FormControl();


  constructor(
    private tokenSerivce: TokenStorageService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private manService: ManagementService,
    private reservationService: ReservationService,
    public router: Router
  ) {
    if (this.authService.hasAdminPermission() === true) {
      this.adminpermission = true;
    }
    if (this.authService.hasBackofficePermission() === true) {
      this.backofficepermission = true;
    }
    if (this.authService.hasTenantPermission() === true) {
      this.tenantpermission = true;
    }
    this.selectedMenu = this.reservationService.GetSelectedMenu();
    this.manService.getOrganisationname().subscribe(
      val => {
        this.orgaName = val;
        this.organisations = ['Alle Organisationen'];
        of<OrganisationName>(...this.orgaName).pipe(
          distinct((p: OrganisationName) => p.name),
          )
          .subscribe(x => this.organisations = [...this.organisations , x.name]);
        if (this.adminpermission) {
          this.selected = 'Alle Organisationen';
          this.manService.SetOrganisation(null);
          this.authService.SetOrganisation(null);
          this.formControl = new FormControl('Alle Organisationen');
          this.disabled = false;
        } else {
          this.manService.getOrganisationnameByTenantId(this.authService.getTokenID()).subscribe(
            name => {
              this.selected = name.name;
              this.manService.SetOrganisation(name);
              this.authService.SetOrganisation(name);
              this.formControl = new FormControl(name.name);
              this.disabled = true;
            }
          );
        }

      }
    );
  }

  ngOnInit() {
    this.username = this.tokenSerivce.getTokenName();
    this.sub = this.route
      .data
      .subscribe(param => {
        this.enabled = param.enabledMenu;
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  logout() {
    this.authService.logout().subscribe(() => {
      if (!this.authService.isUserLoggedIn()) {
        this.redirect();
      }
    });
  }

  redirect() {
    // Get the redirect URL from our auth service
    // If no redirect has been set, use the default
    const redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/login';

    // Set our navigation extras object that passes on our global query params and fragment
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };

    // Redirect the user
    this.router.navigateByUrl(redirect, navigationExtras);
  }

  ngDoCheck() {
    this.selectedMenu = this.reservationService.GetSelectedMenu();
    if (this.manService.GetSaveOrganisation() === 0) {
      this.manService.getOrganisationname().subscribe(
        val => {
          this.orgaName = val;
          this.organisations = ['Alle Organisationen'];
          this.manService.SetSaveOrganisation(1);
          of<OrganisationName>(...this.orgaName).pipe(
            distinct((p: OrganisationName) => p.name),
            )
            .subscribe(x => this.organisations = [...this.organisations , x.name]);
          if (this.adminpermission) {
            this.selected = 'Alle Organisationen';
            this.formControl = new FormControl('Alle Organisationen');
          } else {
            this.manService.getOrganisationnameByTenantId(this.authService.getTokenID()).subscribe(
              name => {
                this.selected = name.name;
                this.manService.SetOrganisation(name);
                this.formControl = new FormControl(name.name);
              }
            );
          }
        }
      );
    }
  }

  applyFilter(filterValue: string) {
    let i = 0;
    if (filterValue === 'Alle Organisationen') {
      this.manService.SetOrganisation(null);
    } else {
      while (this.orgaName[i].name !== filterValue) {
        i = i + 1;
      }
      this.manService.SetOrganisation(this.orgaName[i]);
    }
  }
}
