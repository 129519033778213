import { Injectable } from '@angular/core';

import { throwError, Observable, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { Reservation } from '../model/reservation.model';
import { Parkingspot } from '../model/parkingspot.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tenant } from '../model/tenant.model';
import { ViewReservation } from '../model/viewreservation.model';
import { ParkingspotWeek } from '../model/parkingspotweek.model';
import { ParkingspotViewCarpark } from '../model/parkingspotviewcarpark.model';
import { TenantViewNameAndPlate } from '../model/tenant.model';
import { environment } from 'src/environments/environment';
import { ReservationTableDTO } from '../model/reservationTableDTO.model';
import { NumberPlate } from '../model/numberplate.model';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {

  private apiURL = environment.apiURL + '/general';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    })
  };

  private reservations: Reservation[];
  private date: number = null;
  private selectedMenu: string = null;
  private selectedTab: string = null;
  private EndDate: number = null;
  private parkingspotid: ParkingspotViewCarpark = null;
  private selectedweek: Date;
  private selectedmonth: Date;
  private carparkweek: string;
  private carparkmonth: string;
  private parkingspotidmonth: number;
  private guest: string;

  constructor(
    private http: HttpClient,
  ) { }

  getReservationOList(): Observable<Reservation[]> {
    if (this.reservations != null) {
        return of(this.reservations);
    } else {
      return this.http.get<Reservation[]>(this.apiURL + '/reservationlist', this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
    }
  }

/*   getManageReservationList(id: number): Observable<ManageReservation[]> {
    return this.http.get<ManageReservation[]>(this.apiURL + '/managereservationlist/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  } */

  getManageReservationTable(id: number): Observable<ReservationTableDTO[]> {
    return this.http.get<ReservationTableDTO[]>(this.apiURL + '/reservationtable/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getParkingSpotWeekList(start: number, end: number): Observable<ParkingspotWeek[]> {
    return this.http.get<ParkingspotWeek[]>(this.apiURL + '/parkingspotweeklist/' + start + '-' + end, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getParkingSpotWeekPark() {
    return this.http.get<ParkingspotWeek[]>(this.apiURL + '/parkingspotweeklist', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getParkingSpotViewCarparkByOrganisation(id: number) {
    return this.http.get<ParkingspotViewCarpark[]>(this.apiURL + '/parkingspotViewCarpark/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getViewReservation(id: number | string): Observable<ViewReservation> {
    return this.http.get<ViewReservation>(this.apiURL + '/viewreservation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getReservation(id: number | string): Observable<Reservation> {
    return this.http.get<Reservation>(this.apiURL + '/reservation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postReservation(reservation: Reservation): Observable<Reservation> {
    return this.http.post<Reservation>(this.apiURL + '/reservation', JSON.stringify(reservation), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postReservationRange(reservation: Reservation[]): Observable<Reservation[]> {
    return this.http.post<Reservation[]>(this.apiURL + '/reservations', JSON.stringify(reservation), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteReservation(id: number | string) {
    return this.http.delete(this.apiURL + '/reservation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateReservation(id: number |string, reservation: ViewReservation): Observable<ViewReservation> {
    return this.http.put<ViewReservation>(this.apiURL + '/viewreservations/' + id, reservation, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getParkingspots(): Observable<Parkingspot[]> {
    return this.http.get<Parkingspot[]>(this.apiURL + '/parkingspotlist', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getParkingspot(id: number | string): Observable<Parkingspot> {
    return this.http.get<Parkingspot>(this.apiURL + '/parkingspot/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTenant(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(this.apiURL + '/tenant', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateTenant(id: number |string, tenant: Tenant): Observable<Tenant> {
    return this.http.put<Tenant>(this.apiURL + '/tenant/' + id, tenant, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateNumberplate(id: number, numberplate): Observable<NumberPlate[]> {
    return this.http.put<NumberPlate[]>(this.apiURL + '/numberplate/' + id,  numberplate, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteNumberplate(id: number) {
    return this.http.delete(this.apiURL + '/numberplate/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getNumberplatesOfTenant(id: number) {
    return this.http.get<NumberPlate[]>(this.apiURL + '/numberplatelist/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTenantById(id: number) {
    return this.http.get<Tenant>(this.apiURL + '/tenant/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getViewNewReservation(id: number | string): Observable<TenantViewNameAndPlate> {
    return this.http.get<TenantViewNameAndPlate>(this.apiURL + '/viewnewreservation/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }

 SetNewReservationDate(date: number) {
  this.date = date;
 }

 GetNewReservationDate(): number {
   return this.date;
 }
 SetNewReservationEndDate(date: number) {
  this.EndDate = date;
 }

 GetNewReservationEndDate(): number {
   return this.EndDate;
 }

 SetCarparkWeek(carpark: string) {
   this.carparkweek = carpark;
 }

 GetCarparkWeek(): string {
  return this.carparkweek;
 }

 SetCarparkMonth(carpark: string) {
  this.carparkmonth = carpark;
  }

  GetCarparkMonth(): string {
  return this.carparkmonth;
  }

  SetParkingspotIDMonth(parkingspotid: number) {
    this.parkingspotidmonth = parkingspotid;
  }

  GetParkingspotIDMonth(): number {
    return this.parkingspotidmonth;
  }

  SetGuest(guest: string) {
     this.guest = guest;
  }

  GetGuest(): string {
    return this.guest;
  }

 SetParkingspotID(id: ParkingspotViewCarpark) {
   this.parkingspotid = id;
 }

 GetParkingspotID(): ParkingspotViewCarpark {
   return this.parkingspotid;
 }

 GetSelectedTab(): string {
   return this.selectedTab;
 }

 SetSelectedTab(tab: string) {
  this.selectedTab = tab;
  }

  GetSelectedMenu(): string {
    return this.selectedMenu;
  }

  SetSelectedMenu(tab: string) {
   this.selectedMenu = tab;
   }

  SetWeek(selected: Date) {
    this.selectedweek = selected;
  }

  SetMonth(selected: Date) {
    this.selectedmonth = selected;
  }

  GetWeek(): Date {
    return this.selectedweek;
  }

  GetMonth(): Date {
    return this.selectedmonth;
  }
}
