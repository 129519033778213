import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { PagenotfoundComponent } from './page-not-found/page-not-found.component';

import { UserComponent } from './user/user.component';
import { AuthGuard } from './auth/auth.guard';
import { MenuComponent } from './menu/menu.component';
import { UnsavedChangesGuard } from './user/unsaved-changes.guard';

/**
 * Routing Guards:
 *  - CanActivate to mediate navigation to a route.
 *  - CanActivateChild to mediate navigation to a child route.
 *  - CanDeactivate to mediate navigation away from the current route.
 *  - Resolve to perform route data retrieval before route activation.
 *  - CanLoad to mediate navigation to a feature module loaded asynchronously.
 */

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  {
    path: 'reservation', component: MenuComponent, canActivateChild: [AuthGuard],
    loadChildren: () => import('./reservations/reservations.module').then(mod => mod.ReservationModule),
    data: {
      enabledMenu: true,
    }
  },
  { path: 'user', component: MenuComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: UserComponent, canDeactivate: [UnsavedChangesGuard] }
    ],
    data: {
      enabledMenu: true,
    }
  },
  { path: 'management', component: MenuComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./management/management.module').then(mod => mod.ManagementModule),
    data: {
      enabledMenu: true,
    }
  },
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '404', component: MenuComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: PagenotfoundComponent }
    ],
    data: {
      enabledMenu: true,
    }
  },
  { path: '**', canActivate: [AuthGuard], redirectTo: '/404',
    data: {
      enabledMenu: true,
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
