import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenStorageService } from './tokenStorage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private token: TokenStorageService) {}

    // All HTTP requests are going to go through this method
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let authReq = req;

        const authToken = this.token.getAuthToken();

        if (authToken != null) {
            authReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + authToken } });
        }

        return next.handle(authReq);
    }
}

export const HttpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];

