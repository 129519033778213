import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import {MatButtonModule, MatIconModule, MatInputModule, MatCheckboxModule, MatCardModule} from '@angular/material';
import {SharedUiModule} from '../shared/shared.module';
import { NumberPlateManagementComponent } from './number-plate-management/number-plate-management.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  declarations: [NumberPlateManagementComponent, UserProfileComponent],
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUiModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatCardModule,
  ],
  exports: [
    NumberPlateManagementComponent,
    UserProfileComponent
  ]
})
export class UserModule { }
