import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
import { UserComponent } from '../user/user.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<UserComponent> {

  constructor(
    private dialogService: ConfirmDialogService) {
   }

  canDeactivate(component:UserComponent){
    let i = 0;
    let change = false;
    while(i < component.control.length){
      if(component.control[i].dirty){
        change = true;
      }
      i += 1;
    }
    if(change === false || component.saved === true) {
      return true;
    }
    this.dialogService.open({
      message: 'Wollen Sie ihre Eingaben verwerfen?',
      confirmText: 'Ja',
      cancelText: 'Nein',
    });
    return this.dialogService.confirmed();
  }
}
