import {Component, Input, OnInit} from '@angular/core';
import {Tenant} from '../../model/tenant.model';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'parkplatz-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @Input()
  user: Tenant;
  @Input()
  control: [];
  tenant = false;
  backoffice = false;
  admin = false;

  constructor(
    public authService: AuthService
  ) {

  }

  ngOnInit() {
    if (this.authService.hasTenantPermission()) {
      this.tenant = true;
    }
    if (this.authService.hasBackofficePermission()) {
      this.backoffice = true;
    }
    if (this.authService.hasAdminPermission()) {
      this.admin = true;
    }
  }
}
